var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-slider mt-4"},[_c('div',{ref:"slider",staticClass:"home-slider-content pb-4",attrs:{"id":"slider"}},[_vm._l((_vm.shop),function(item,index){return _c('div',{key:index,staticClass:"home-slider-item"},[_c('div',{class:_vm.isMobile
            ? 'home-slider-item-content'
            : 'home-slider-item-content-dekstop'},[_c('ProductCard',{attrs:{"item":item,"parentindex":_vm.parentindex,"index":index}})],1)])}),_c('div',{staticClass:"home-slider-item"},[_c('div',{class:_vm.isMobile
            ? 'home-slider-item-content'
            : 'home-slider-item-content-dekstop'},[_c('span',{class:_vm.isMobile
              ? 'home-slider-item-view-all'
              : 'home-slider-item-view-all-dekstop',on:{"click":function($event){return _vm.toCategory()}}},[_vm._v(" View All")])])])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }